import React from "react";
import styles from './real-sponsors.module.css'
import {graphql, useStaticQuery} from "gatsby";

const RealSponsors = () => {

  const sponsors = useStaticQuery(graphql`
      query realSponsors {
          allContentfulRealSponsor {
              nodes {
                  id
                  nazwaFirmy
                  poziomSponsoringu
                  linkDoStronySponsora
                  logo {
                      fixed(width: 560) {
                          src
                          tracedSVG
                      }
                  }
              }
          }
      }
  `);

 const fitlerSponsors = poziomSponsoringu => sponsors.allContentfulRealSponsor.nodes.filter(s => s.poziomSponsoringu === poziomSponsoringu);


  return (
    <section id="real-sponsors"  className={`sectionWrapper ${styles.realSponsors}`}>
      <div className="innerWrapper">
        <h1 className="sectionTitle">Sponsors & partners</h1>
        <div className={styles.spWrapper}>
          <div className={styles.row}>
            <h2 style={{backgroundColor: '#2199e3'}}>Platinum</h2>
            {fitlerSponsors('platinium').map(s => (
              <figure key={s.id}>
                <a target="_blank" rel="noopener noreferrer" href={s.linkDoStronySponsora}><img src={s.logo.fixed.src || s.logo.fixed.tracedSVG} alt={s.nazwaFirmy} title={s.nazwaFirmy}/></a>
              </figure>
            ))}
          </div>
          <div className={styles.row}>
            <h2 style={{backgroundColor: '#f8921f'}}>Gold</h2>
            {fitlerSponsors('gold').map(s => (
              <figure key={s.id} className={styles.gold}>
                <a target="_blank" rel="noopener noreferrer" href={s.linkDoStronySponsora}><img src={s.logo.fixed.src || s.logo.fixed.tracedSVG} alt={s.nazwaFirmy} title={s.nazwaFirmy}/></a>
              </figure>
            ))}
          </div>
          <div className={styles.row}>
            <h2 style={{backgroundColor: '#dfe0e1'}}>Silver</h2>
            {fitlerSponsors('silver').map(s => (
              <figure key={s.id} style={{maxWidth: '200px'}}>
                <a target="_blank" rel="noopener noreferrer" href={s.linkDoStronySponsora}><img src={s.logo.fixed.src || s.logo.fixed.tracedSVG} alt={s.nazwaFirmy} title={s.nazwaFirmy}/></a>
              </figure>
            ))}
          </div>
          <div className={styles.row}>
            <h2 style={{backgroundColor: '#f05929'}}>Bronze</h2>
            {fitlerSponsors('bronze').map(s => (
              <figure key={s.id} >
                <a target="_blank" rel="noopener noreferrer" href={s.linkDoStronySponsora}><img src={s.logo.fixed.src || s.logo.fixed.tracedSVG} alt={s.nazwaFirmy} title={s.nazwaFirmy}/></a>
              </figure>
            ))}
          </div>
        </div>
      </div>
    </section>

  )
};

export default RealSponsors;
