import React from "react";
import styles from './organisers.module.css'
import {graphql, useStaticQuery} from "gatsby";
import Card from "../card/card";
import AdvBg from "../advisoryBoard/adv-bg";
import { connect } from "react-redux";

const Organisers = ({isOnTop}) => {
  const {allContentfulOrganizers: { nodes }} = useStaticQuery(graphql`
      query OrgQuery {
          allContentfulOrganizers {
              nodes {
                  id
                  name
                  picture {
                      sizes (maxWidth: 320) {
                          src
                      }
                      fixed(cropFocus: FACE, width: 300, height: 300) {
                          src
                      }
                  }
                  bio {
                      bio
                      json
                  }
                  longBio {
                      json
                  }
              }
          }
      }

  `);


  const renderNodes = () => {
    const sortedNodes = nodes.concat().sort((a,b) => a.name.split(' ')[1].localeCompare(b.name.split(' ')[1], 'en'));
    return sortedNodes.map( o =>  <Card key={o.id} name={o.name} bio={o.bio} pic={o.picture.fixed.src} longBio={o.longBio} from={'organisers'}/>)
  };

  return (
    <section id="organisers" className={`sectionWrapper ${styles.organiser}`} style={{zIndex: isOnTop === 'organisers' ? 99 : 1} }>
      <div className="innerWrapper">
        <h1 className="sectionTitle">Organisers</h1>
        <p className={styles.content}>The conference is driven by Polish Flutter Community
          with the cooperation of word-class Flutter experts.</p>
        <div className={styles.wrapper}>
          {renderNodes()}
        </div>
        <div className={styles.powered}><p>Powered by </p> <a target="_blanc" href="https://leancode.pl/"> <img src="leanCode.png" alt="lean code"/></a></div>
      </div>
      <AdvBg/>
    </section>
  )
};
export default connect(state => ({
  isOnTop: state.app.isOnTop
}), null)(Organisers);
