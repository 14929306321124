import React from 'react'
import CookieConsent from "react-cookie-consent";
import Hero from '../components/hero/hero'
import Navigation from '../components/navigation/navigation'
import About from '../components/about/about'
import { AdvisoryBoard } from '../components/advisoryBoard/advisoryBoard'
import Venue from "../components/venue/venue";
import Tickets from "../components/tickets/tickets";
import Programme from "../components/programme/programme";
import Qas from "../components/faq/qas";
import Speakers from "../components/speakers/speakers";
import Sponsors from "../components/sponsors/sponsors";
import Organisers from "../components/organisers/organisers";
import Footer from "../components/footer/footer";
import styles from './index.module.css';
import SEO from "../components/SEO/seo";
import BackToTop from "../components/BackToTop/backToTop";
import ScrollProgress from "../components/scrollProgress/scrollProgress";
import Newsletter from "../components/newsletter/newsletter";
import RealSponsors from "../components/real-sponsors/real-sponsors";
import MediaPartners from "../components/media-partners/media-partners";
import AppLink from "../components/appLink/appLink";



const style = {
  fontFamily: 'Roboto',
  backgroundSize: 'cover',
  backgroundPosition: 'top center',
  width: '100%'
};
class RootIndex extends React.Component {

  render() {

    return (
      <div style={style}>
        <AppLink/>
        <ScrollProgress/>
        <SEO/>
        <Navigation/>
        <Hero/>
        <About/>
        <div className={styles.wrapper}>
          <AdvisoryBoard/>
          <Venue/>
          <Tickets/>
        </div>
        <Speakers/>
        <Programme/>
        <Newsletter/>
        <Qas/>
        <Organisers/>
        <Sponsors/>
        <MediaPartners/>
        <Footer/>
        <CookieConsent buttonStyle={{ backgroundColor: "#f05929", fontSize: "13px" }}>
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <BackToTop/>
      </div>
    )
  }
}

export default RootIndex
