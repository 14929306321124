import React from "react";
import './hero-bg.css';

const HeroBg = () => {

  return (
    <div>
      <div className="wrapperH">
        <img className="circle" src="circle.svg" alt=""/>
        <img className="circleBlue" src="circle-blue.svg" alt=""/>
        <img className="lb" src="lb.svg" alt="flutter europe conference"/>
        <img className="rb" src="rb.svg" alt="flutter europe conference"/>
        <img className="mb" src="mb.svg" alt="flutter europe conference"/>
        <img className="mt" src="mt.svg" alt="flutter europe conference"/>
      </div>
    </div>
  )
};

export default HeroBg;
