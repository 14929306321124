import React, { useState } from "react";
import styles from './programItem.module.css';
import Modal from '../modal/modal'
import Card from "../card/card";
import { toggleTop } from "../../state/app";
import { connect } from 'react-redux';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const TopSpeaker = ({ speaker: { picture, name, twitter, bio } }) => (
  <div className={styles.top}>
    <img src={picture.fixed.src} alt="speaker"/>
    <div>
      <span>{name}</span>
      {twitter && (<a href={twitter}>
        <svg fill="#002161" xmlns="http://www.w3.org/2000/svg" width="16.48" height="13.434" viewBox="0 0 16.48 13.434">
          <path id="Path_191"
                d="M1346.1 49.56a6.874 6.874 0 0 0 4.97-1.4 3.459 3.459 0 0 1-3.16-2.36 3.841 3.841 0 0 0 1.51-.07 3.379 3.379 0 0 1-1.94-1.2 3.259 3.259 0 0 1-.74-2.16 3.5 3.5 0 0 0 1.51.41 3.4 3.4 0 0 1-1.4-2.06 3.357 3.357 0 0 1 .37-2.45 9.725 9.725 0 0 0 6.97 3.54c-.02-.22-.05-.42-.06-.62a3.384 3.384 0 0 1 3.95-3.49 3.258 3.258 0 0 1 1.79.92.249.249 0 0 0 .27.07 7.039 7.039 0 0 0 1.82-.69c.05-.03.09-.05.17-.09a3.412 3.412 0 0 1-1.4 1.82c.01.01.01.03.02.04.3-.06.61-.12.91-.2s.59-.19.92-.31a3.587 3.587 0 0 1-.39.51c-.38.38-.78.76-1.17 1.13a.3.3 0 0 0-.11.25 9.812 9.812 0 0 1-3.09 7.41 8.827 8.827 0 0 1-4.7 2.36 9.863 9.863 0 0 1-5.19-.44 9.164 9.164 0 0 1-1.75-.86c-.03-.01-.04-.02-.08-.06z"
                className="cls-1" data-name="Path 191" transform="translate(-1346.1 -37.648)"/>
        </svg>
        <span>{twitter.split('/')[3]}</span>
      </a>)}
      <div>{documentToReactComponents(bio && bio.json)}</div>
    </div>
  </div>
);

const BottomSpeaker = ({ name, longBio }) => (
  <div className={styles.bottom}>
    <p>About {name}</p>
    <p>{documentToReactComponents(longBio && longBio.json)}</p>
  </div>
);

const ProgramItem = ({ el, dispatch }) => {
  const [show, showModal] = useState(false);
  const [advanced, showAdvancedModal] = useState(false);

  const handleClose = () => {
    document.body.classList.remove('modal-open');
    dispatch(toggleTop('hero'));
    showModal(false);
    showAdvancedModal(false);

  };
  const handleOpen = (type) => {
    dispatch(toggleTop('programme'));
    document.body.classList.add('modal-open');
    type === 'beginner' ? showModal(true) : showAdvancedModal(true);
  };

  return (
    <tr key={el.id} className={el.type === 'other' ? '' : styles.progItem}>
      <td className={styles.time}>{el.time}</td>
      <td className={el.type === 'other' ? '' : styles.beginner}>
        <div>
          <div className={styles.title}>
            {el.title === 'Afterparty at Klub Park!' ? (<a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/jj5obpxe7N36xFSCA"> Afterparty at Klub Park!</a>) : el.title}
          </div>

          {el.speaker && (
            <Card name={el.speaker.name} bio={el.speaker.bio} pic={el.speaker.picture.fixed.src} smallVersion={true}/>
          )}
          {el.secondSpeaked && (
            <Card name={el.secondSpeaked.name} bio={el.secondSpeaked.bio} pic={el.secondSpeaked.picture.fixed.src} smallVersion={true}/>
          )}
          <Modal handleClose={handleClose} show={show} blue={true}>
            <div className={styles.modalContent}>
              {el.speaker && <TopSpeaker speaker={el.speaker}/>}
              {el.secondSpeaked && <TopSpeaker speaker={el.secondSpeaked}/>}
              <div className={styles.middle}>
                <h1>{el.title}</h1>
                <div>{el.parsedDescription}</div>
              </div>
              {el.speaker && (<BottomSpeaker name={el.speaker.name} longBio={el.speaker.longBio}/>)}
              {el.secondSpeaked && (<BottomSpeaker name={el.secondSpeaked.name} longBio={el.secondSpeaked.longBio}/>)}
            </div>
          </Modal>
        </div>
        {el.description && (<img className={styles.button} role="button" src="button-plus-blue.svg" alt="open"
                                 onClick={() => handleOpen('beginner')}/>)}
      </td>

      {el.adv && (
        <>
          <td className={styles.advanced}>
            <div>
              <div className={styles.title}>{el.advTitle}</div>
              {el.advSpeaker && (
                <Card name={el.advSpeaker.name} bio={el.advSpeaker.bio} pic={el.advSpeaker.picture.fixed.src}
                      smallVersion={true}/>
              )}
              {el.ss && (
                <Card name={el.ss.name} bio={el.ss.bio} pic={el.ss.picture.fixed.src} smallVersion={true}/>
              )}
              <Modal handleClose={handleClose} show={advanced}>
                <div className={styles.modalContent}>
                  {el.adv.speaker && <TopSpeaker speaker={el.adv.speaker}/>}
                  {el.ss && <TopSpeaker speaker={el.ss}/>}
                  <div className={styles.middle}>
                    <h1>{el.advTitle}</h1>
                    <div>{el.advDescription}</div>
                  </div>
                  {el.adv.speaker && (<BottomSpeaker name={el.adv.speaker.name} longBio={el.adv.speaker.longBio}  />)}
                  {el.ss && (<BottomSpeaker name={el.ss.name} longBio={el.ss.longBio}/>)}
                </div>
              </Modal>
            </div>
            {el.advDescription && (<img className={styles.button} role="button" src="button-plus.svg" alt="open"
                                        onClick={() => handleOpen('advanced')}/>)}
          </td>
        </>
      )}
    </tr>
  );
};

export default connect()(ProgramItem);
