import React from "react";
import styles from "./footer.module.css";
import {graphql, useStaticQuery} from "gatsby";

const Footer = () => {

  const footer = useStaticQuery(graphql`
      query footer {
          contentfulFooter {
              codeOfConduct
              privacyPolicy
          }
      }`);

  const {
  } = footer.contentfulFooter;


  return (
    <footer className={styles.footer}>
      <div className={`sectionWrapper ${styles.inner}`}>
        <nav className={styles.nav}>
          <ul>
            <li>
              <a href="mailto:contact@fluttereurope.dev">Contact</a>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer"  href="/PRIVACY_POLICY_Flutter_Europe.pdf">Privacy policy</a>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer"  href="/REGULATION _of_Flutter_Europe_2020_Conference.pdf">Regulations</a>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer"  href="/FlutterEuropeCodeOfConduct.pdf">Code of Conduct</a>
            </li>
          </ul>
          <ul className={styles.social}>
            <li><a target="_blank" rel="noopener noreferrer"  href="https://twitter.com/fluttereurope">
              <svg fill="#002161" xmlns="http://www.w3.org/2000/svg" width="16.48" height="13.434"
                   viewBox="0 0 16.48 13.434">
                <path id="Path_191"
                      d="M1346.1 49.56a6.874 6.874 0 0 0 4.97-1.4 3.459 3.459 0 0 1-3.16-2.36 3.841 3.841 0 0 0 1.51-.07 3.379 3.379 0 0 1-1.94-1.2 3.259 3.259 0 0 1-.74-2.16 3.5 3.5 0 0 0 1.51.41 3.4 3.4 0 0 1-1.4-2.06 3.357 3.357 0 0 1 .37-2.45 9.725 9.725 0 0 0 6.97 3.54c-.02-.22-.05-.42-.06-.62a3.384 3.384 0 0 1 3.95-3.49 3.258 3.258 0 0 1 1.79.92.249.249 0 0 0 .27.07 7.039 7.039 0 0 0 1.82-.69c.05-.03.09-.05.17-.09a3.412 3.412 0 0 1-1.4 1.82c.01.01.01.03.02.04.3-.06.61-.12.91-.2s.59-.19.92-.31a3.587 3.587 0 0 1-.39.51c-.38.38-.78.76-1.17 1.13a.3.3 0 0 0-.11.25 9.812 9.812 0 0 1-3.09 7.41 8.827 8.827 0 0 1-4.7 2.36 9.863 9.863 0 0 1-5.19-.44 9.164 9.164 0 0 1-1.75-.86c-.03-.01-.04-.02-.08-.06z"
                      className="cls-1" data-name="Path 191" transform="translate(-1346.1 -37.648)"/>
              </svg>
            </a></li>
            <li>
              <a target="_blank" rel="noopener noreferrer"  href="https://www.facebook.com/fluttereurope">
                <svg fill="#002161" xmlns="http://www.w3.org/2000/svg" width="13.421" height="13.43"
                     viewBox="0 0 13.421 13.43">
                  <path id="Path_192"
                        d="M1307.1 49.06v-9.39a.142.142 0 0 0 .02-.06 2.363 2.363 0 0 1 2.32-1.96c2.92.02 5.85.01 8.77.01a2.3 2.3 0 0 1 2.31 2.31v8.79a2.224 2.224 0 0 1-1.29 2.07 4.162 4.162 0 0 1-.73.24h-2.6c0-.02-.01-.04-.01-.06v-4.9c0-.03.01-.07.01-.11h2.21v-2.24h-2.22c0-.34-.01-.66 0-.98a.5.5 0 0 1 .55-.5h1.53a.528.528 0 0 0 .13-.01v-2.22h-1.95a1.316 1.316 0 0 0-.44.08 2.746 2.746 0 0 0-1.83 1.99 3.84 3.84 0 0 0-.1 1.17v.48h-1.63a.375.375 0 0 0-.01.1v2.03c0 .09.03.11.11.11h1.53v5.07h-4.69a.142.142 0 0 0-.06-.02 2.241 2.241 0 0 1-1.72-1.28c-.09-.23-.14-.48-.21-.72z"
                        className="cls-1" data-name="Path 192" transform="translate(-1307.1 -37.65)"/>
                </svg>
              </a>
            </li>
          </ul>

        </nav>
        <div className={styles.rights}>All Rights Reserved © 2019</div>
      </div>
      <p className={styles.disclaimer}>Flutter and the related logo are trademarks of Google LLC. Flutter Europe is not
        affiliated with or otherwise sponsored by Google LLC.</p>
    </footer>
  );
}

export default Footer;
