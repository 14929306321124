import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Member from '../member/member'
import style from './advisoryBoard.module.css'
import AdvBg from "./adv-bg";


export const AdvisoryBoard = () => {
  const getMembers = (data) => {
    return data.map(({node}) => (
        <Member key={node.id} name={node.name}
                longBio={node.bio.bio} bio={node.shortBio.shortBio}
                url={node.pic.fixed.src} twitter={node.twitter} twitterLogin={node.twitterLogin}/>
      )
    );
  };
  const adv = useStaticQuery(
    graphql`
        query MyQuery {
            allContentfulAdvisor {
                edges {
                    node {
                        id
                        name
                        pic {
                            file {
                                url
                            }
                            fixed(width: 400, cropFocus: FACE, height: 400) {
                                src
                            }
                        }
                        bio {
                            bio
                        }
                        shortBio {
                            shortBio
                        }
                        twitter
                        twitterLogin
                    }
                }
            }
        }
    `);


  return (
    <section id='advisory' className={`sectionWrapper ${style.section}`}>
      <div className="innerWrapper">
        <h1 className="sectionTitle">Advisory board</h1>
        <div className={style.wrapper}>
          {getMembers(adv.allContentfulAdvisor.edges)}
        </div>
      </div>
      <AdvBg/>
    </section>
  );
};
