import React from "react";
import './tic-bg.css';
import AnimatedImage from "../animatedImage";

const Tic = () => {

  return (
    <div>
      <div className="wrapper">
        <AnimatedImage customClassName="lT shape" src="tickets/l.svg" alt="flutter europe conference"/>
        <AnimatedImage customClassName="mT shape" src="tickets/m.svg" alt="flutter europe conference"/>
        <AnimatedImage customClassName="rT shape" src="tickets/r.svg" alt="flutter europe conference"/>
      </div>
    </div>
  )
};

export default Tic;
