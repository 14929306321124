import React, {useState} from "react";
import styles from './tickets.module.css'
import { useStaticQuery, graphql } from "gatsby";
import Ticket from "../ticket/ticket";
import Tic from "./tic";

const Tickets = () => {

  const tickets = useStaticQuery(graphql`
  query TicketsQuery {
  allContentfulTicket (sort: {fields: ticketOrder, order: ASC}) {
    nodes {
      active
      id
      price
      type
      buyUrl
      ticketOrder
      displayType
      description {
          description
      }
        background {
          file {
              url
              fileName
          }
      }
    }
  }
}
  
  `);


  const renderTickets = tickets.allContentfulTicket.nodes.map((t, i) => <Ticket key={t.id} tic={t} i={i} />)
  return (
    <section id="tickets" className={`sectionWrapper ${styles.tickets}`}>
      <div className="innerWrapper">
        <h1 className="sectionTitle">Tickets</h1>
        <p className={styles.content}>Due to an overwhelming interest, we’re sold out! Thank you for your interest in Flutter Europe, and stay tuned for the next edition.</p>
        <div className={styles.ticWrapper}>
          {renderTickets}
        </div>
      </div>
      <p className={styles.vat}>* All listed prices do not include 23% VAT</p>
      <Tic/>
    </section>

  )
};

export default Tickets
