import React,{useState} from "react";
import styles from './qa.module.css';
import { Remarkable } from 'remarkable';
const md = new Remarkable();

const Qa = ({qa}) => {

  const [open, openClose] = useState(styles.close);

  return (
      <div className={`${styles.wrapper} ${open}`} >
        <div className={styles.qa}>
          <div className={styles.question}>
            <button onClick={() => openClose(styles.open)} className={styles.btnOpen}><img src="button-plus.svg" alt="open"/>
              <p>{qa.question}</p>
            </button>
          </div>
          <div className={styles.popup}>
            <button onClick={() => openClose(styles.close)} className={styles.btnClose}><img src="button-plus.svg" alt="open"/></button>
            <p className={styles.questionCopy}>{qa.question}</p>
            <div className={styles.answer} dangerouslySetInnerHTML={{__html:md.render(qa.answer.answer)}}></div>
          </div>

        </div>

      </div>
  );
};

export default Qa
