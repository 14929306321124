import React, {useRef} from "react";
import './adv-bg.css';
import AnimatedImage from '../animatedImage'


const AdvBg = () => {

  return (
    <div className="wrapper">
      <AnimatedImage customClassName="circleA shape" src="adv/tl.svg"/>
      <AnimatedImage customClassName="blA shape" src="adv/bl.svg" />
      <AnimatedImage customClassName="tmA shape" src="adv/tm.svg" />
    </div>
  )
};

export default AdvBg;
