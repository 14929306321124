import React, {useLayoutEffect, useRef, useState} from "react";


const AnimatedImage = props => {
  const innerRef = useRef(null);

  const [animated, updateAnimated] = useState('');

  const isInViewport = () => {
    const topX = innerRef.current.getBoundingClientRect().top;
    const animate =  topX <= (window.innerHeight || document.documentElement.clientHeight);
    if (animate) {
      innerRef.current.classList.add('animated');
      updateAnimated('animated');
    }
  };

  useLayoutEffect(()=> {
    window.addEventListener('scroll', isInViewport );
    return () => window.removeEventListener('scroll', isInViewport)
  }, []);

  return <img ref={innerRef} src={props.src} className={`${props.customClassName}`} alt="flutter europe conf shapes"/>;
};

export default AnimatedImage
