import React from "react";
import './newsletterBg.css';
import AnimatedImage from "../animatedImage";

const NewsletterBg = () => {

  return (
    <div>
      <div className="wrapper">
        <AnimatedImage customClassName="rbProg shape" src="prog/rb.svg" alt="flutter europe conference"/>
        <AnimatedImage customClassName="lbProg shape" src="prog/lb.svg" alt="flutter europe conference"/>
      </div>
    </div>
  )
};

export default NewsletterBg;
