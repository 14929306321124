import React from "react";
import styles from './sponsors.module.css'
import Sponsor from "../sponsor/sponsor";
import {graphql, useStaticQuery} from "gatsby";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import RealSponsors from "../real-sponsors/real-sponsors";

const Sponsors = () => {

  const sponsors = useStaticQuery(graphql`
      query Sponsors {
          allContentfulSponsor (sort: {fields: order}) {
              nodes {
                  id
                  color
                  package
                  description {
                      json
                  }
              }
          }
      }
  `);

  const renderSponsors = (special) => sponsors.allContentfulSponsor.nodes.map(s => {
    const content = documentToReactComponents(s.description.json);

    if (special) {
      if(s.package.toLowerCase() === 'platinum') {
        return (
            <Sponsor key={s.id} sp={{title:s.package, color:s.color, content:content}}/>
        )
      }
      return null;
    } else {
      if(s.package.toLowerCase() !== 'platinum') {
        return (
          <Sponsor key={s.id} sp={{title:s.package, color:s.color, content:content}}/>
        )
      }
      return null;
    }
  });

  return (
    <div>
      <RealSponsors/>
      <section id="sponsors"  className={`sectionWrapper ${styles.sponsors}`}>
        <div className="innerWrapper">
          <p className={styles.content}>Would you like to support one of the most popular community events?</p>
          <p className={styles.content}>Consider one of our partnership packages</p>
          <div className={styles.spWrapper}>
            <div className={styles.row3}>
              {renderSponsors()}
            </div>
            <div className={styles.row}>
              {renderSponsors(true)}
            </div>
          </div>
        </div>
      </section>
    </div>


  )
};

export default Sponsors;
