import React from "react";
import './prog-bg.css';
import AnimatedImage from "../animatedImage";

const Prog = () => {

  return (
    <div>
      <div className="wrapper">
        <AnimatedImage customClassName="ltProg shape" src="prog/lt.svg" alt="flutter europe conference"/>
        <AnimatedImage customClassName="cirProg shape" src="prog/cir.svg" alt="flutter europe conference"/>
        <AnimatedImage customClassName="cirWhiteProg shape" src="prog/rb.svg" alt="flutter europe conference"/>
        <AnimatedImage customClassName="lmProg shape" src="prog/rm.svg" alt="flutter europe conference"/>
      </div>
    </div>
  )
};

export default Prog;
