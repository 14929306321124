import React, {useEffect, useState} from "react";
import styles from "./scroll.module.css"

const ScrollProgress = () => {

  const [scrollPosition, scroll] = useState(0);

  const getScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    return (winScroll / height) * 100;
  };

  useEffect(()=> {
    window.addEventListener('scroll', ()=> {
      scroll(getScroll());
    });
    return () => window.removeEventListener('scroll', ()=>{})

  },[scrollPosition]);

  return (
    <div className={styles.scrollWrapper}>
      <div className={styles.inner} style={{'width': `${scrollPosition}%`}}/>
    </div>
  )
};

export default ScrollProgress;
