import React from "react";
import styles from './media-partners.module.css'
import {graphql, useStaticQuery} from "gatsby";
import { connect } from "react-redux";

const MediaPartners = ({isOnTop}) => {
  const {allContentfulMediaPartners: { nodes }} = useStaticQuery(graphql`
      query MediaPartnersQuery {
          allContentfulMediaPartners {
              nodes {
                  name
                  link
                  logo {
                      fixed(width: 560) {
                          src
                          tracedSVG
                      }
                  }
              }
          }
      }

  `);

  const renderNodes = () => {
    const sortedNodes = nodes.concat().sort((a,b) => a.name.localeCompare(b.name, 'en'));
    return sortedNodes.map( s =>
        <figure key={s.name}>
                <a href={s.link} target="_blank" rel="noopener noreferrer" ><img src={s.logo.fixed.src || s.logo.fixed.tracedSVG} alt={s.name} title={s.name}/></a>
        </figure>)
  };

  return (
    <section id="media-partners"  className={`sectionWrapper ${styles.mediaPartners}`}>
      <div className="innerWrapper">
        <h1 className="sectionTitle">Media Partners</h1>
        <div className={styles.spWrapper}>
          <div className={styles.row}>
            {renderNodes()}
          </div>
        </div>
      </div>
    </section>

  )
};

export default MediaPartners;
