import React, {useState, useCallback} from "react";
import styles from './newsletter.module.css';
import $jsonp from "../../lib/jsonp";
import Modal from "../modal/modal";
import validate from "../../lib/validate"

;
import NewsletterBg from "./newsletterBg";

const Newsletter = () => {


  const url = 'https://dev.us3.list-manage.com/subscribe/post?u=f483d165b29a487d6440177ca&id=1915d3bf07&&subscribe=SUBSCRIBE&_=1567259869607';
  const [isSending, setIsSending] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [resp, setResp] = useState('');

  const validateEmail = (email) => {
    setEmail(email);
    if (validate.emailCheck(email)) {
      setIsValid(true)
    } else {
      setIsValid(false);
    }
  };
  const validateName = (name) => {
    setName(name);
    if (validate.nameCheck(name)) {
      setIsValidName(true);
    } else {
      setIsValidName(false);
    }
  };

  const sendRequest = useCallback(async () => {
    if (isSending) return;
    // update state
    setIsSending(true);
    const res = await fetch(`${url}&EMAIL=${email}&NAME=${name}`,{
      mode: "cors"
    });
    setIsSending(false)
  }, [isSending, email, name]);

  const sendJson = useCallback(async () => {
    if (isSending) return;
    setIsSending(true);
    // send the actual request
    const res = await $jsonp.send(`https://dev.us3.list-manage.com/subscribe/post-json?u=f483d165b29a487d6440177ca&id=1915d3bf07&&c=c&group%5B23209%5D%5B1%5D=1&group%5B23209%5D%5B2%5D=1&b_f483d165b29a487d6440177ca_1915d3bf07=&subscribe=SUBSCRIBE&_=1567595656088&EMAIL=${email}&NAME=${name}`, {
      callbackName: 'c',
      onSuccess: function(res){
        if (res.result) {
          setResp(res.msg);
        }
      },
      onTimeout: function(){
        console.log('timeout!');
      },
      timeout: 5
    });
    setIsSending(false)
  }, [isSending, email, name]);

  return (
    <section id="newsletter" className={`sectionWrapper ${styles.programme}`} onSubmit={()=> false}>
      <div className="innerWrapper">
        <div className={styles.formWrapper}>
          <form id="newsletter" className={styles.form} action="">
            <p className={styles.join}>You REALLY don't want to miss it.<br/>
              Join our newsletter to receive updates!
            </p>
            <input className={styles.inputText} type="text" placeholder="your email" onChange={e => validateEmail(e.target.value)}/>
            {!isValid && email && <p className={styles.error}>Invalid email</p>}
            <input className={styles.inputText} type="text" placeholder="your name" onChange={e => validateName(e.target.value)}/>
            {!isValidName && name && <p className={styles.error}> Name too short</p>}
            <label  className={styles.checkbox}><input type="checkbox" onChange={e => setChecked(e.target.checked) }/>
              Thereby I consent to processing my data by the organizers of Flutter Europe Conference
            </label>
            {!isChecked && email && name && <p className={styles.error}>Please note that this consent is obligatory</p>}
            <label className={styles.checkbox}><input type="checkbox"/>
              Thereby I agree to receive marketing materials about Flutter Conference such us updates on agenda and speakers
            </label>
            <button disabled={
              isSending || !isValidName ||!isValid || !isChecked
            } onClick={() => sendJson()} className={styles.button} type="submit">subscribe</button>
            <p className={styles.privacy}>If you’d like to change or cancel your enrollment status, email us at <a target="_blank"
              href="mailto:privacy@fluttereurope.dev">privacy@fluttereurope.dev</a>
            </p>
          </form>
          <Modal show={!!resp} handleClose={()=>setResp('')} dhtml={resp}/>
        </div>
      </div>
      <NewsletterBg/>
    </section>

  )
};

export default Newsletter;
