import React, {useState} from "react";
import styles from './qas.module.css'
import { useStaticQuery, graphql } from "gatsby";
import Qa from "../qa/qa";
import Faq from "./faq";

const Qas = () => {

  const qa = useStaticQuery(graphql`
  query FaqQuery {
      allContentfulQuestion {
          nodes {
              answer {
                  answer
              }
              question
              id
          }
      }
}
  
  `);

  const renderQa = qa.allContentfulQuestion.nodes.map(q => <Qa key={q.id} qa={q} />)

  return (
    <section id="faq" className={`sectionWrapper ${styles.qas}`}>
      <div className="innerWrapper">
        <h1 className="sectionTitle">FAQ</h1>
        <div className={styles.ticWrapper}>
          {renderQa}
        </div>
      </div>
      <Faq/>
    </section>

  )
};

export default Qas
