import React, { useState } from "react";
import styles from './programme.module.css';
import Prog from "./prog";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ProgramItem from "../programItem/programItem";
import { connect } from 'react-redux';

const Programme = ({ isOnTop }) => {
  const { allContentfulProgramme: { nodes } } = useStaticQuery(graphql`
      query programme {
          allContentfulProgramme (sort: {fields: time}) {
              nodes {
                  id
                  day
                  time
                  title
                  type
                  speaker {
                      name
                      twitter
                      picture {
                          fixed (cropFocus: FACE, resizingBehavior: FILL, width: 200, height:200) {
                              src
                          }
                      }
                      bio {
                          json
                      }
                      longBio {
                          json
                      }
                  }
                  secondSpeaked {
                      name
                      twitter
                      picture {
                          fixed (cropFocus: FACE, resizingBehavior: FILL, width: 200, height:200) {
                              src
                          }
                      }
                      bio {
                          json
                      }
                      longBio {
                          json
                      }
                  }
                  description {
                      json
                  }
              }
          }
      }`);

  const [day, setDay] = useState('day one');

  const getProgByDay = (day) => {
    return nodes.filter(n => n.day === day).sort((a, b) => a.time - b.time);
  };
  const buildTableRecords = (day) => {
    const allData = getProgByDay(day);
    const advanced = allData.filter(el => el.type === 'advanced');
    const rest = allData.filter(el => el.type !== 'advanced');
    const data = rest.map(el => {
      const adv = advanced.find(a => a.time === el.time);
      if (el.time === (adv && adv.time)) {
        return {
          ...el,
          parsedDescription: documentToReactComponents(el.description && el.description.json),
          adv: {...adv},
          advTitle: adv.title,
          advSpeaker: { ...adv.speaker },
          ss: adv.secondSpeaked,
          advDescription: documentToReactComponents(adv.description && adv.description.json)
        }
      }
      return {
        ...el,
        parsedDescription: documentToReactComponents(el.description && el.description.json)
      }
    });
    return data;
  };

  const renderProg = (day) => {
    return buildTableRecords(day).map(el => {
      return (
        <ProgramItem key={el.id} el={el}/>
      )
    })
  };

  return (
    <section id="programme" className={`sectionWrapper ${styles.programme}`}
             style={{ zIndex: isOnTop === 'programme' ? 99 : 1 }}>
      <div className="innerWrapper">
        <h1 className="sectionTitle">Programme</h1>
        <div className={styles.tabs}>
          <button className={day === 'day one' ? styles.active : styles.tab} onClick={() => setDay('day one')}><p>Day 1
            (January 23)</p></button>
          <button className={day === 'day two' ? styles.active : styles.tab} onClick={() => setDay('day two')}><p>Day 2
            (January 24)</p></button>
        </div>
        <div className={styles.tabsContent}>
          <div className={styles.tableWrapper}>
            <table>
              <colgroup>
                <col style={{ width: '20%' }}/>
                <col style={{ width: '40%' }}/>
                <col style={{ width: '40%' }}/>
              </colgroup>
              <tbody>
              {renderProg(day)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Prog/>
    </section>
  )
};

export default connect(state => ({
  isOnTop: state.app.isOnTop
}), null)(Programme);
