import React, {useState} from "react";
import styles from './venue.module.css';
import GoogleMapComponentWithMarker from '../maps/maps';
import VenueBg from "./venue-bg";

const Venue =  () => {

  const [currentImg, setCurrent] = useState('1.jpg');

  return(
    <section id="venue" className={`sectionWrapper ${styles.venue}`}>
      <div className="innerWrapper">
        <h1 className="sectionTitle">Venue</h1>
        <div className={styles.content}>
          <img src="exclamation.svg" alt="exclamation"/>
          <p>We're meeting in the astonishing Copernicus Science
            Centre in Warsaw, the Capital City of Poland. </p>
        </div>
        <div className={styles.gallery}>
          <img src={currentImg} alt="location Copernicus science centre i Warsaw " className={styles.main}/>
          <div className={styles.grid}>
            <img src={"2.jpg"} alt="venue place ocation Copernicus science centre in Warsaw"/>
            <img src={"3.jpg"} alt="location Copernicus science centre in Warsaw"/>
            <img src={"4.jpg"} alt="Copernicus science centre in Warsaw" className={styles.last}/>
          </div>
        </div>
        <div className={styles.mapsWrapper}>
          <GoogleMapComponentWithMarker
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBR9ZrsAcqAmaKMoy8UWitcj22Nn3D2lwM"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            isMarkerShown
          />
        </div>
      </div>
      <VenueBg/>

    </section>

)};

export default Venue
