import React from 'react';
import styles from './hero.module.css'
import Button from "../button/button";
import Countdown from "../countdown/countdown"
import HeroBg from './hero-bg';
import {graphql, useStaticQuery} from "gatsby";
import { navigate } from "@reach/router";

const Hero = () => {

  const data = useStaticQuery(graphql`
      query myHero {
          contentfulHero {
              title
              subtitle
          }
      }
  `);

  const {title, subtitle} = data.contentfulHero;

  return (
    <section className={styles.heroWrapper}>
      <div className={styles.hero}>
        <div className={styles.heroDetails}>
          <h1 className={styles.heroHeadline}>{title}</h1>
          <p className={styles.heroTitle}>{subtitle}</p>
          <Button text={'check details'} click={() => navigate('#programme')} />
        </div>
        <div className={styles.clock}>
          <Countdown/>
        </div>
      </div>
      <HeroBg/>
    </section>
  )
};
export default Hero;
