import React from "react";
import styles from './cicrcles.module.css';
import Circle from "../circle/circle";


const Circles = () => {
  return (
    <div className={styles.circles}>
      <Circle content={'2'} label={'days'} delay={445}/>
      <Circle content={'1'} label={'technology'} delay={0}/>
      <Circle content={'30+'} label={'speakers'} delay={225}/>
      <Circle content={'25+'} label={'talks'} delay={180}/>
    </div>
  )
}

export default Circles;
