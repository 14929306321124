import React, {useEffect, useState} from "react";
import styles from './countdown.module.css'

export default () => {

  const confDate = new Date('January 23, 2020 09:00:00');
  const finishDate = new Date('January 25, 2020 18:00:00');

  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [started, setStart] = useState(false);
  const [finished, setFinish] = useState(false);


  const format = (d) => d < 10 ? `0${d}` : d;

  const count = () => {
    const now = new Date();
    if (now > confDate) {
      setStart(true);
    }
    if (now > finishDate) {
      setStart(false);
      setFinish(true);
    }
    let delta = Math.abs(confDate - now)/1000;
    let days = format(Math.floor(delta / 86400));
    setDay(days);
    delta = delta - days * 86400;
    let hours = format(Math.floor(delta / 3600) % 24);
    setHour(hours);
    delta -= hours * 3600;
    let minutes = format(Math.floor(delta / 60) % 60);
    setMinute(minutes);
    delta -= minutes * 60;
    let seconds = format(Math.floor(delta));
    setSecond(seconds);
  };

  useEffect(() => count(),[]);


  useEffect(() => {
    const timer = setInterval(count, 1000);
    return () => clearInterval(timer)
  },[second]);

  if (started) {
    return(
      <div className={styles.round}>
        <div className={styles.display}>
          <h2>It’s happening right now!</h2>
        </div>
      </div>
    )
  }
  if (finished) {
    return(
      <div className={styles.round}>
        <div className={styles.display}>
          <h2>Getting ready for the next edition.</h2>
        </div>
      </div>
    )
  }

  return(
  <div className={styles.round}>
    <div className={styles.display}>
      <div className={styles.col}>
        <div className={styles.digit}> {day}</div>
        <div className={styles.label}>days</div>
      </div>
      <div className={styles.col}>
        <div className={styles.digit}> {hour}</div>
        <div className={styles.label}>hours</div>
      </div>
      <div className={styles.col}>
        <div className={styles.digit}>{minute}</div>
        <div className={styles.label}>minutes</div>
      </div>
      <div className={styles.col}>
        <div className={`${styles.digit} ${styles.sec}`}>{second}</div>
        <div className={styles.label}>seconds</div>
      </div>
    </div>
  </div>
)}
