import React from "react";
import './burger.css'

const Burger = ({active, onclick}) => {

  const open = active ? 'is-active' : '';

  return (<button onClick={onclick} className={`hamburger hamburger--squeeze ${open}`} type="button">
  <span className="hamburger-box">
    <span className="hamburger-inner"></span>
  </span>
    </button>
  );
};
export default Burger;
