import React from "react";
import styles from './logo.module.css'

const Logo = () => (
  <div className={styles.container}>
    <picture>
      <source media="(max-width: 880px)" srcSet="logo-mobile.svg"/>

      <img className={styles.logo} src="logo.png" alt="Flutter Europe Conference Logo"/>
    </picture>

  </div>
);
export default Logo;
