import React from "react";
import styles from'./modal.module.css'

const Modal = ({ handleClose, show, children, dhtml, blue=false }) => {
  const showHideClassName = show ? styles.block : styles.none;

  if (dhtml) return (
    <div className={showHideClassName}>
      <section className={styles.modalMain}>
        <div  dangerouslySetInnerHTML={{__html:dhtml}}></div>
        <button className={styles.close} onClick={handleClose}><img src="button-plus.svg" alt="open"/></button>
      </section>
      <div onClick={handleClose} className={styles.overlay}></div>
    </div>
  );

  return (
    <div className={showHideClassName}>
      <section className={styles.modalMain}>
        {children}
        <button className={styles.close} onClick={handleClose}><img src={blue ? 'button-plus-blue.svg' : 'button-plus.svg'} alt="open"/></button>
      </section>
      <div onClick={handleClose} className={styles.overlay}></div>
    </div>
  );
};

export default Modal
