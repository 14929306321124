import React from "react";
import styles from './sponsor.module.css'

const Sponsor = ({sp}) => {
  const special = sp.title.toLowerCase() ==='platinum' ? styles.platinum : '';
  if (sp.title === 'platinum') {
    return null;
  }

  return (
    <section className={`${styles.card} ${special}`}>
      <div className={styles.title} style={{backgroundColor: sp.color}}>
        <h1>{sp.title}</h1>
        {sp.title ==='gold' && <div className={styles.popular}>Sold Out</div>}
      </div>
      <div className={styles.body}>
        <div className={styles.list}>
          {sp.content}
        </div>
      </div>
      {special ? <div className={styles.buttonWrapper}>
        <p>Contact us for custom offer on Platinum package</p>
          <a target="_blank" rel="noopener noreferrer"  href="/FlutterEurope_PitchDeck_Become_a_Partner.pdf" className={styles.selectButton}>details</a>
      </div>
        : <a target="_blank" rel="noopener noreferrer"  href="/FlutterEurope_PitchDeck_Become_a_Partner.pdf" className={styles.selectButton}>details</a>
      }

    </section>
  )
};

export default Sponsor;
