import React from "react";
import styles from './ticket.module.css';
const Ticket = ({tic}) => {

  const displayType = tic.displayType;
  const disabled = displayType === 'soon' || displayType === 'sold';

  let no = 0;
  const bgMap = () => {
  if (disabled) {
    no++;
    return `url("soon${no}.svg")`
  }
  };

  const style = {background : bgMap()};

  if (displayType === 'soon') {
    return (
      <div className={`${styles.card} ${styles.soon}`} style={style}>
        <div className={styles.cardInner}>
          <div className={styles.wrapper}>
            <div className={styles.type}>{tic.type}</div>
            <div className={styles.des} dangerouslySetInnerHTML={{__html: tic.description && tic.description.description }}></div>
          </div>
          <div className={styles.price}>{tic.price}<small>€</small><sup> *</sup></div>
          <a target="_blank" rel="noopener noreferrer"  onClick={(e) => e.preventDefault() } href={tic.buyUrl} className={styles.button}>soon</a>
        </div>
      </div>
    );
  } else if (displayType === 'sold') {
    return (
      <div className={`${styles.card} ${styles.soon}`} style={style}>
        <div className={styles.cardInner}>
          <div className={styles.wrapper}>
            <div className={styles.type}>{tic.type}</div>
            <div className={styles.des} dangerouslySetInnerHTML={{__html: tic.description && tic.description.description }}></div>
          </div>
          <a target="_blank" rel="noopener noreferrer"  onClick={(e) => e.preventDefault() } href={tic.buyUrl} className={styles.button}>sold</a>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.card} style={{background: `url("${tic.background && tic.background.file.url}"`}}>
        <div className={styles.cardInner}>
          <div className={styles.type}>{tic.type}</div>
          <div className={styles.price}>{tic.price}<small>€</small><sup> *</sup></div>
          <div className={styles.des} dangerouslySetInnerHTML={{__html: tic.description && tic.description.description }}></div>
          <a target="_blank" rel="noopener noreferrer"  href={tic.buyUrl} className={styles.button}>buy</a>
        </div>
      </div>
    );
  }
};

export default Ticket
