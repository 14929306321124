import React, {useState} from "react";
import styles from './card.module.css';
import { Remarkable } from 'remarkable';
import Modal from '../modal/modal'
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {toggleTop} from "../../state/app";
import  { connect } from 'react-redux';


const md= new Remarkable();

const Card = ({name, bio, pic, longBio, twitter, smallVersion=false, topic=null, from, dispatch}) => {
  const content = documentToReactComponents(bio && bio.json);
  const longContent = documentToReactComponents(longBio && longBio.json);

  const [show, showModal] = useState(false);

  const handleClose = () => {
    document.body.classList.remove('modal-open');
    showModal(false);
    dispatch(toggleTop('hero'));
  };
  const handleOpen = () => {
    document.body.classList.add('modal-open');
    showModal(true);
    console.log(from)
    switch (from) {
      case 'speakers' :
        dispatch(toggleTop('speakers'));
        break;
      case 'organisers' :
        dispatch(toggleTop('organisers'));
    }
  };

  if (smallVersion) {
    return (
      <div className={styles.smallWrapper}>
        <img className={styles.smallCircle} src={pic} alt={`${name} picture`}/>
        <div className={styles.smallContent}>
          <div>{name}</div>
          <div>{content}</div>
        </div>
      </div>
    )
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.circle}>
        <img className={styles.pic} src={pic} alt={`${name} picture`}/>
      </div>
      <div className={styles.content}>
        {longBio && <button onClick={handleOpen} className={styles.button}><img src="button-plus.svg" alt="open"/></button>}
        <div className={styles.name}>{name}</div>
        <div className={styles.bio}>{content}</div>
      </div>
      <Modal show={show} handleClose={handleClose}>
        <div className={styles.modalCircle}>
          <img className={styles.modalPic} src={pic} alt={`${name} picture`}/>
        </div>
        <div className={styles.modalContent} >
          <h1 className={styles.name}>{name}</h1>
          {twitter && (
            <a className={styles.twitter} target="_blank" href={twitter}>
              <svg fill="#002161" xmlns="http://www.w3.org/2000/svg" width="16.48" height="13.434" viewBox="0 0 16.48 13.434">
                <path id="Path_191"
                      d="M1346.1 49.56a6.874 6.874 0 0 0 4.97-1.4 3.459 3.459 0 0 1-3.16-2.36 3.841 3.841 0 0 0 1.51-.07 3.379 3.379 0 0 1-1.94-1.2 3.259 3.259 0 0 1-.74-2.16 3.5 3.5 0 0 0 1.51.41 3.4 3.4 0 0 1-1.4-2.06 3.357 3.357 0 0 1 .37-2.45 9.725 9.725 0 0 0 6.97 3.54c-.02-.22-.05-.42-.06-.62a3.384 3.384 0 0 1 3.95-3.49 3.258 3.258 0 0 1 1.79.92.249.249 0 0 0 .27.07 7.039 7.039 0 0 0 1.82-.69c.05-.03.09-.05.17-.09a3.412 3.412 0 0 1-1.4 1.82c.01.01.01.03.02.04.3-.06.61-.12.91-.2s.59-.19.92-.31a3.587 3.587 0 0 1-.39.51c-.38.38-.78.76-1.17 1.13a.3.3 0 0 0-.11.25 9.812 9.812 0 0 1-3.09 7.41 8.827 8.827 0 0 1-4.7 2.36 9.863 9.863 0 0 1-5.19-.44 9.164 9.164 0 0 1-1.75-.86c-.03-.01-.04-.02-.08-.06z"
                      className="cls-1" data-name="Path 191" transform="translate(-1346.1 -37.648)"/>
              </svg>
              <span>{twitter.split('/')[3]}</span>
            </a>
          )}
          <h2 className={styles.topic}>{topic}</h2>
          <div className={styles.longBio}>{longContent}</div>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(Card)
