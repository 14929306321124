import React, {useState, useEffect} from "react";
import {GoogleApiWrapper, InfoWindow, Map, Marker} from 'google-maps-react';

const infoWindow = {
  fontSize: '13px'
};

const styles = require('./style.json');

class Maps extends React.Component {

  state = {infoWindow:false, activeMarker:{}, mapLoaded: false};

  onMarkerClick = (props, marker) => this.setState(prevState =>({activeMarker: marker, infoWindow:true}));

  onMapClicked = (props) => {
    if (this.state.infoWindow) {
      this.setState({infoWindow: false, activeMarker:null});
    }
  };
  onMarkerMounted = element => this.onMarkerClick(element.props, element.marker);

  handleMapIdle = () => this.setState({mapLoaded: true});

  render() {
    return (
      <Map
        google={window.google}
        zoom={15}
        initialCenter={{lat: 52.24185, lng: 21.02872}}
        styles={styles}
        disableDefaultUI={true}
        onClick={this.onMapClicked}
        onIdle={this.handleMapIdle}
      >
        {
          this.state.mapLoaded && (
            <Marker
              ref={this.onMarkerMounted}
              onClick={this.onMarkerClick}
            />
          )
        }

        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.infoWindow}>
          <div >
            <h1 style={infoWindow}>Copernicus Science Centre</h1>
            <p>20 Wybrzeże Kościuszkowskie st., 00-390 Warsaw</p>
          </div>
        </InfoWindow>
      </Map>
    );
  }

};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBR9ZrsAcqAmaKMoy8UWitcj22Nn3D2lwM'
})(Maps)
