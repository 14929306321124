import React from "react";
import styles from './speakers.module.css'
import {graphql, useStaticQuery} from "gatsby";
import Card from "../card/card";
import AdvBg from "../advisoryBoard/adv-bg";
import { connect } from 'react-redux';

const Speakers = ({isOnTop}) => {
    const {allContentfulSpeaker: {nodes}} = useStaticQuery(graphql`
        query speakersQuery {
            allContentfulSpeaker (sort: {fields: speakersOrder, order: ASC}) {
                nodes {
                    id
                    name
                    twitter
                    picture {
                        sizes (maxWidth: 320) {
                            src
                        }
                        fixed(cropFocus: FACE, width: 300, height: 300) {
                            src
                        }
                    }
                    bio {
                        json
                    }
                    longBio {
                        json
                    }
                    topic
                }
            }
        }

    `);


    const renderNodes = () => {

      return nodes.filter(s => s.name !== 'TBD').map(o => <Card key={o.id} name={o.name} bio={o.bio} topic={o.topic} pic={o.picture && o.picture.fixed.src}
                                        longBio={o.longBio} twitter={o.twitter} from={'speakers'}/>)
    };

    return (
      <section id="speakers" className={`sectionWrapper ${styles.speakers}`} style={{zIndex: isOnTop === 'speakers' ? 99 : 1} }>
        <div className="innerWrapper">
          <h1 className="sectionTitle">Speakers</h1>
          <div className={styles.content}>
            <p>We will host over 30 speakers over 2 days.
              Want to know more?
              Join our <a href="#newsletter">newsletter</a> to stay in touch.
            </p>
            <p>The conference is driven by Polish Flutter Community
              with the cooperation of world-class Flutter experts.</p>
          </div>
          <div className={styles.wrapper}>
            {renderNodes()}
          </div>
        </div>
        <AdvBg/>
      </section>

    )
  }
;

export default connect(state => ({
  isOnTop: state.app.isOnTop
}), null)(Speakers)
