import React from "react";
import './venue-bg.css';
import AnimatedImage from '../animatedImage'

const VenueBg = () => {

  return (
    <div className="wrapper">
      <AnimatedImage customClassName="rV shape" src="venue/r.svg"/>
      <AnimatedImage customClassName="lV shape" src="venue/l.svg" />
      <AnimatedImage customClassName="wcA shape" src="venue/wc.svg" />
    </div>
  )
};

export default VenueBg;
