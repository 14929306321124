import React, {useState} from 'react'
import styles from './navigation.module.css'
import {navigate} from '@reach/router'
import Logo from "../Logo/logo";
import Burger from "../burger/burger";

export default () => {

  const [active, setActive] = useState(false);

  const open = active ? styles.open : '';

  return (
    <div className={styles.header}>
      <Logo/>
      <nav role="navigation" className={open}>
        <ul className={styles.navigation}>
          <li role="button" className={styles.navigationItem} onClick={() => navigate('#advisory')}>Advisory&nbsp;Board</li>
          <li role="button" className={styles.navigationItem} onClick={() => navigate('#venue')}>Venue</li>
          <li role="button" className={styles.navigationItem} onClick={() => navigate('#tickets')}>Tickets</li>
          <li role="button" className={styles.navigationItem} onClick={() => navigate('#speakers')}>Speakers</li>
          <li role="button" className={styles.navigationItem} onClick={() => navigate('#programme')}>Programme</li>
          <li role="button" className={styles.navigationItem} onClick={() => navigate('#faq')}>FAQ</li>
        </ul>
        <ul className={styles.mobileNav}>
          <li role="button" onClick={() => navigate('#advisory')}>Advisory&nbsp;Board</li>
          <li role="button" onClick={() => navigate('#venue')}>Venue</li>
          <li role="button" onClick={() => navigate('#tickets')}>Tickets</li>
          <li role="button" onClick={() => navigate('#speakers')}>Speakers</li>
          <li role="button" onClick={() => navigate('#programme')}>Programme</li>
          <li role="button" onClick={() => navigate('#faq')}>FAQ</li>
        </ul>
        <ul className={styles.social}>
          <li className={styles.navigationItem}>
            <a target="_blank" rel="noopener noreferrer"  href="https://www.facebook.com/fluttereurope">
              <img src={'fb.svg'} alt="Facebook" />
            </a>
            </li>
          <li className={styles.navigationItem}>
            <a target="_blank" rel="noopener noreferrer"  href="https://twitter.com/fluttereurope">
              <img src={'twitter.svg'} alt="Twitter"/>
            </a>
          </li>
        </ul>
        <Burger active={active} onclick={() => setActive(!active)}/>
      </nav>
    </div>
  )
}
