import React from 'react';
import styles from './about.module.css'
import Circles from "../circles/circles";
import Ab from "./ab";

const About = () => {

  return (
    <section id={'about'} className={`sectionWrapper ${styles.about}`}>
      <div className="innerWrapper">
        <div className={styles.content}>
          <div className={styles.one}>
            <h1 className={styles.aboutHeadline}>About conference</h1>
            <p className={styles.hedlineContent}>Flutter Europe is an initiative driven by European
              Flutter Communities to gather all Flutter passionates
              and learn to create beautiful apps! Our goal is to share
              Flutter knowledge, improve Flutter skills and expand
              Flutter community.</p>
          </div>
          <div className={styles.two}>
            <h2 className={styles.titleTwo}>The whole community in one place</h2>
            <p className={styles.contentTwo}>It’s not about source code. It’s about people! Want to meet the most
              recognizable Flutter developers from Europe and talk with them?
              This is the place! Flutter Europe is the easiest way to become a part
              of the greatest developers community ever.&nbsp;&nbsp;<img className={styles.heart} src="heart.svg" alt="heart icon"/> </p>
            <h3 className={styles.titleThree}>Only Flutter</h3>
            <p className={styles.contentThree}>It ain’t another mobile dev conference. Can’t speak in Kotlin, Swift or JavaScript?
              Don’t worry! Here only Dart matters. We believe that Flutter is mature enough
              to have its own dedicated conference. Do you agree?
            </p>
          </div>
          <Circles/>
        </div>
      </div>
      <Ab/>
    </section>
  )
}
export default About;
