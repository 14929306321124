import React from "react";
import './ab-bg.css';
import AnimatedImage from "../animatedImage";

const Ab = () => {

  return (
    <div>
      <div className="wrapper">
        <AnimatedImage customClassName="lAbout shape" src="about/rt.svg" alt="flutter europe conference"/>
        <AnimatedImage customClassName="mAbout shape" src="about/mb.svg" alt="flutter europe conference"/>
        <AnimatedImage customClassName="rAbout shape" src="about/rb.svg" alt="flutter europe conference"/>
      </div>
    </div>
  )
};

export default Ab;
