import React, {useState, useEffect} from "react";
import styles from './backToTop.module.css';
import styled from 'styled-components';

const Wrapper = styled.div`
display: ${props => props.hide ? 'none' : 'block'}
`;

const BackToTop = () => {

  const [display, setDisplay] = useState('none');

  const scrollF = () => {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      setDisplay('block')
    } else {
      setDisplay('none');
    }
  };

  useEffect(()=> {
    window.addEventListener('scroll', scrollF);
    return () => window.removeEventListener('scroll', scrollF)
  });



  const topFunction = () => {
    if (document.body.classList.contains('modal-open')) {
      return false;
    }
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    window.location.hash = ""
  };

  return (
    <Wrapper>
      <div className={styles.container} style={{display: display}}>
        <button className={styles.button} onClick={topFunction}>&#8593;</button>
      </div>
    </Wrapper>
)};
export default BackToTop;
