import React from "react";
import './faq-bg.css';
import AnimatedImage from "../animatedImage";

const Faq = () => {

  return (
    <div>
      <div className="wrapper">
        <AnimatedImage customClassName="lF shape" src="faq/l.svg" alt="flutter europe conference"/>
        <AnimatedImage customClassName="mF shape" src="faq/m.svg" alt="flutter europe conference"/>
        <AnimatedImage customClassName="rF shape" src="faq/r.svg" alt="flutter europe conference"/>
      </div>
    </div>
  )
};

export default Faq;
