import React, { useEffect, useState } from "react";
import styles from './appLink.module.css'

const AppLink = () => {
  const [show, handleShow] = useState(false);
  const handleClose = () => {
    handleShow(false);
  };

  useEffect(() => {
    setTimeout(() => handleShow(true), 1500)
  }, []);
  return (
  <div className={show ? styles.container : styles.hideContainer}>
    <button onClick={handleClose} className={styles.close}><img src="button-plus.svg" alt="open"/></button>
    <a onClick={handleClose} className={styles.app} href="https://apps.apple.com/us/app/flutter-europe/id1485095896" target="_blank" rel="noopener noreferrer">
      <img className={styles.logo} src="appstore.png" alt="appstore app"/>
    </a>
    <a onClick={handleClose} className={styles.app} href="https://play.google.com/store/apps/details?id=dev.fluttereurope.conferenceapp" target="_blank" rel="noopener noreferrer">
      <img className={styles.logo} src="play.png" alt="google play app"/>
    </a>
  </div>
)};
export default AppLink;
