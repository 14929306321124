import React from "react";
import styles from './cicrcle.module.css';
import './cicrcle.css'


const Circle = ({content, label, delay=0}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.circle}>
        <div className={`${styles.inner} inner`} style={{animationDelay: `${delay}ms`}}>
        </div>
        <div className={styles.content}>
          {content}
        </div>
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  )
}

export default Circle;
